import { useState } from "react";
import { toast } from "react-toastify";
import { IconButton, Typography } from "@mui/material";
import { FaPlay, FaStop } from "react-icons/fa";

import { ConfirmationModal } from "@components/modals";
import { Paper, Switcher, Table } from "@components/new";
import { useStationDetails } from "@providers";
import { ConnectorAPI, TransactionAPI } from "@api";

import { mapper, reducer, columns } from "./config";
import styles from "./styles.module.scss";
import { TransactionModal } from "./transaction-modal";

export const Connectors = () => {
  const { station, runningTransactions } = useStationDetails();

  const data = station.connectors.map(mapper);

  const [statusesMap, setStatusesMap] = useState(data.reduce(reducer, new Map()));
  const [transactionModal, setTransactionModal] = useState({ connectorId: "", visible: false });
  const [confirmationModal, setConfirmationModal] = useState({
    id: "",
    visible: false,
    enabled: false,
  });

  const onConnectorButtonClick = async (id) => {
    const transaction = runningTransactions.get(id);

    if (transaction) {
      try {
        await TransactionAPI.stop(id);
        toast.success("Session has been successfullyb stopped");
      } catch ({ message }) {
        toast.error(message);
      }
    } else {
      setTransactionModal({ connectorId: id, visible: true });
    }
  };

  const renderItem = (item, field) => {
    const value = item[field];

    switch (field) {
      case "operationalStatus": {
        const { id } = item;

        const enabled = statusesMap.get(id);

        const onChange = () => setConfirmationModal({ visible: true, id, enabled });

        return <Switcher checked={enabled} onChange={onChange} />;
      }
      case "buttons": {
        const transaction = runningTransactions.get(item.id);
        const enabled = ["Charging", "Preparing"].includes(item.connectorStatus);

        return (
          <IconButton
            onClick={() => onConnectorButtonClick(item.id)}
            disabled={!enabled}
            disableRipple={!enabled}
            classes={{ disabled: styles.disabled }}
          >
            {(transaction ? FaStop : FaPlay)({ size: 20, className: styles.button })}
          </IconButton>
        );
      }
      case "powerRating": {
        return station.powerRating;
      }
    }

    return value;
  };

  const onTransactionModalClose = () => {
    setTransactionModal({ visible: false, connectorId: "" });
  };

  const onConfirm = async () => {
    const status = !confirmationModal.enabled;
    await ConnectorAPI.updateStatus(confirmationModal.id, status);
    setStatusesMap(new Map(statusesMap.set(confirmationModal.id, status)));
    setConfirmationModal((modal) => ({ ...modal, visible: false }));
  };

  const onReject = () => {
    setConfirmationModal((modal) => ({ ...modal, visible: false }));
  };

  const text = `Are you sure you want to ${
    confirmationModal.enabled ? "Disable" : "Enable"
  } this connector?`;

  return (
    <>
      <Paper className={styles.container}>
        <Typography className={styles.title}>Connectors</Typography>
        <Table data={data} columns={columns} renderItem={renderItem} />
      </Paper>
      <TransactionModal {...transactionModal} onClose={onTransactionModalClose} />
      <ConfirmationModal
        open={confirmationModal.visible}
        text={text}
        onConfirm={onConfirm}
        onReject={onReject}
      />
    </>
  );
};
